exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-golosovoj-poisk-google-perspektivy-i-optimizacziya-js": () => import("./../../../src/pages/golosovoj-poisk-google-perspektivy-i-optimizacziya.js" /* webpackChunkName: "component---src-pages-golosovoj-poisk-google-perspektivy-i-optimizacziya-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-friendly-algoritm-ot-poiskovoj-sistemy-google-js": () => import("./../../../src/pages/mobile-friendly-algoritm-ot-poiskovoj-sistemy-google.js" /* webpackChunkName: "component---src-pages-mobile-friendly-algoritm-ot-poiskovoj-sistemy-google-js" */),
  "component---src-pages-portfolio-bestchef-js": () => import("./../../../src/pages/portfolio/bestchef.js" /* webpackChunkName: "component---src-pages-portfolio-bestchef-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-izstekla-js": () => import("./../../../src/pages/portfolio/izstekla.js" /* webpackChunkName: "component---src-pages-portfolio-izstekla-js" */),
  "component---src-pages-portfolio-mirshome-js": () => import("./../../../src/pages/portfolio/mirshome.js" /* webpackChunkName: "component---src-pages-portfolio-mirshome-js" */),
  "component---src-pages-razrabotka-sajta-dlya-biznesa-js": () => import("./../../../src/pages/razrabotka-sajta-dlya-biznesa.js" /* webpackChunkName: "component---src-pages-razrabotka-sajta-dlya-biznesa-js" */),
  "component---src-pages-sozdat-sajt-dlya-avtomojki-js": () => import("./../../../src/pages/sozdat-sajt-dlya-avtomojki.js" /* webpackChunkName: "component---src-pages-sozdat-sajt-dlya-avtomojki-js" */),
  "component---src-pages-sozdat-sajt-dlya-shinomontazha-js": () => import("./../../../src/pages/sozdat-sajt-dlya-shinomontazha.js" /* webpackChunkName: "component---src-pages-sozdat-sajt-dlya-shinomontazha-js" */),
  "component---src-pages-sozdat-sajt-dlya-sto-js": () => import("./../../../src/pages/sozdat-sajt-dlya-sto.js" /* webpackChunkName: "component---src-pages-sozdat-sajt-dlya-sto-js" */),
  "component---src-pages-sozdat-sajt-nedorogo-i-kachestvenno-js": () => import("./../../../src/pages/sozdat-sajt-nedorogo-i-kachestvenno.js" /* webpackChunkName: "component---src-pages-sozdat-sajt-nedorogo-i-kachestvenno-js" */),
  "component---src-pages-sozdat-sajt-portfolio-na-zakaz-js": () => import("./../../../src/pages/sozdat-sajt-portfolio-na-zakaz.js" /* webpackChunkName: "component---src-pages-sozdat-sajt-portfolio-na-zakaz-js" */),
  "component---src-pages-trebovaniya-k-kartinkam-na-sajte-ot-google-i-yandeks-js": () => import("./../../../src/pages/trebovaniya-k-kartinkam-na-sajte-ot-google-i-yandeks.js" /* webpackChunkName: "component---src-pages-trebovaniya-k-kartinkam-na-sajte-ot-google-i-yandeks-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vneshnie-ssylki-na-sajt-js": () => import("./../../../src/pages/vneshnie-ssylki-na-sajt.js" /* webpackChunkName: "component---src-pages-vneshnie-ssylki-na-sajt-js" */),
  "component---src-pages-zablokirovat-polzovatelya-v-google-drive-teper-realno-js": () => import("./../../../src/pages/zablokirovat-polzovatelya-v-google-drive-teper-realno.js" /* webpackChunkName: "component---src-pages-zablokirovat-polzovatelya-v-google-drive-teper-realno-js" */),
  "component---src-pages-zakazat-sozdanie-sajta-vizitki-pod-klyuch-js": () => import("./../../../src/pages/zakazat-sozdanie-sajta-vizitki-pod-klyuch.js" /* webpackChunkName: "component---src-pages-zakazat-sozdanie-sajta-vizitki-pod-klyuch-js" */)
}

